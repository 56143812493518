
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class StudyRouting extends Vue {
  private routingList(): any[] | null {
    const routeName = this.$route.params.classCategoryCode ?? this.$route.params.route
    const routingList = this.routingMap[routeName]
    if (!routingList) return null

    return routingList
  }
  private studyRoutingIndex(): number | null {
    const routingList = this.routingList()
    if (!routingList) return null

    const path = location.pathname
    for (let i = 0; i < routingList.length; i++) {
      if (routingList[i].path == path) {
        return i
      }
    }
    return 0
  }
  private prevData(): object {
    const idx = this.studyRoutingIndex()
    const routingList = this.routingList()
    if (idx === null || !routingList) return { url: '#' }

    let prevIdx = idx - 1
    prevIdx = prevIdx < 0 ? 0 : prevIdx
    return {
      text: routingList[idx].preText,
      url: routingList[prevIdx].path,
    }
  }
  private nextData(): object | null {
    const idx = this.studyRoutingIndex()
    const routingList = this.routingList()
    if (idx === null || !routingList) return { url: '#' }

    let nextIdx = idx + 1
    nextIdx = nextIdx >= routingList.length ? routingList.length - 1 : nextIdx
    return {
      text: routingList[idx].nextText,
      url: routingList[nextIdx].path,
    }
  }
  private saveText(): string | null {
    const idx = this.studyRoutingIndex()
    const routingList = this.routingList()
    if (idx === null || !routingList) return null

    return routingList[idx].saveText
  }

  private routingMap = {
    // パラメータ[:route]をキーに遷移パターンを取得
    // pathとURLを比較して現在表示しているページを判定
    // 前後要素のpathとpreText/nextTextでリンクボタンを生成している
    KK: [
      { path: '/student/subjects/test-1', preText: '', nextText: '' },
      { path: '/student/confirmation/test-1', preText: '', nextText: '学習を始める' },
      { path: '/student/study-challenge/test-1/1', preText: '', nextText: '次の問題' },
      { path: '/student/study-challenge/test-1/2', preText: '前の問題', nextText: '答え合わせをする' },
      { path: '/student/study-collect/test-1/1', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/test-1/1', preText: '', nextText: '確認テスト一覧へ戻る' },
      { path: '/student/confirmation/test-1', preText: '', nextText: '' },
    ],
    'homework-1': [
      { path: '/student/subjects/homework-1', preText: '', nextText: '' },
      { path: '/student/confirmation/homework-1', preText: '', nextText: '学習を始める' },
      { path: '/student/study-challenge/homework-1/3', preText: '', nextText: '答え合わせをする' },
      { path: '/student/study-collect/homework-1/3', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/homework-1/3', preText: '', saveText: '中断する', nextText: '次の問題' },
      { path: '/student/study-challenge/homework-1/4', preText: '', nextText: '答え合わせをする' },
      { path: '/student/study-collect/homework-1/4', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/homework-1/4', preText: '', nextText: '結果を表示する' },
      { path: '/student/study-result/homework-1/3', preText: '', nextText: '宿題一覧へ戻る' },
      { path: '/student/confirmation/homework-1', preText: '', nextText: '' },
    ],
    'understanding-1': [
      { path: '/student/study-challenge/understanding-1/5', preText: '', nextText: '次の問題' },
      { path: '/student/study-challenge/understanding-1/6', preText: '前の問題', nextText: '答え合わせをする' },
      { path: '/student/study-collect/understanding-1/5', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/understanding-1/5', preText: '', nextText: '演習問題を解く' },
      { path: '/student/study-challenge/practice-1/7', preText: '', nextText: '' },
    ],
    'practice-1': [
      { path: '/student/study-challenge/practice-1/7', preText: '', nextText: '答え合わせをする' },
      { path: '/student/study-collect/practice-1/7', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/practice-1/7', preText: '', nextText: '次の問題' },
      { path: '/student/study-challenge/practice-1/8', preText: '', nextText: '答え合わせをする' },
      { path: '/student/study-collect/practice-1/8', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/practice-1/8', preText: '', nextText: '結果を表示する' },
      { path: '/student/study-result/practice-1/7', preText: '', nextText: '項目リスト' },
      { path: '/student/curriculum-s-units', preText: '', nextText: '' },
    ],
    'ability-test-1': [
      { path: '/student/study-challenge/ability-test-1/9', preText: '', nextText: '次の問題' },
      { path: '/student/study-challenge/ability-test-1/10', preText: '前の問題', nextText: '答え合わせをする' },
      { path: '/student/study-collect/ability-test-1/9', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/ability-test-1/9', preText: '', nextText: '一覧へ戻る' },
      { path: '/student/assist-material/select', preText: '', nextText: '' },
    ],
    'drill-practice-1': [{ path: '/student/study-drill/drill-practice-1/1', preText: '', nextText: '一覧へ戻る' }],
    'regular-1': [
      { path: '/student/study-challenge/regular-1/7', preText: '', nextText: '答え合わせをする' },
      { path: '/student/study-collect/regular-1/7', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/regular-1/7', preText: '', nextText: '次の問題' },
      { path: '/student/study-challenge/regular-1/8', preText: '', nextText: '答え合わせをする' },
      { path: '/student/study-collect/regular-1/8', preText: '', nextText: '結果を登録する' },
      { path: '/student/study-explanation/regular-1/8', preText: '', nextText: '結果を表示する' },
      { path: '/student/study-result/regular-1/7', preText: '', nextText: '一覧へ戻る' },
      { path: '/student/subjects/regular-1', preText: '', nextText: '' },
    ],
  }
}
