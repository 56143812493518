















































import { Component, Mixins } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioImaged from '@/components/atoms/RadioImaged.vue'
import StudyRouting from '@/views/student/study/StudyRouting.vue'

@Component({
  components: {
    ButtonBase,
    RadioImaged,
  },
})
export default class StudyChallenge extends Mixins(StudyRouting) {
  get contentSource(): string {
    return '/student/study/challenge/challenge-' + this.$route.params.id
  }
}
